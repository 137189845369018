<template>
	<div class="login-view">
		<v-container>
			<div class="login-content">
				<h3 class="text-center">ورود به سایت</h3>

				<v-form class="login-form mt-5" @submit.prevent="login">
					<v-text-field label="نام کاربری" type="text" outlined v-model="creds.username"></v-text-field>
					<v-text-field label="رمز عبور" type="password" outlined v-model="creds.password"></v-text-field>

					<v-btn depressed type="submit" color="primary" class="login-btn" :loading="loading.login">
						ورود
					</v-btn>
				</v-form>
			</div>
		</v-container>
	</div>
</template>

<script>

export default {
	name: 'LoginView',
	data() {
		return {
			loading: {
				login: false
			},
			creds: {
				username: '',
				password: ''
			}
		}
	},
	methods: {
		login() {
			/* call the api */
			this.loading.login = true;

			/* make data */
			var data = new FormData();
			data.append("username", this.creds.username);
			data.append("password", this.creds.password);

			/* make call to server */
			this.$http.post("loginTechnician", data)
				.then((result) => {
					if (result.data.status == "ok") {
						/* add technician data in local storage */
						localStorage.setItem("technician", JSON.stringify(result.data.data.technician));

						this.$swal.fire({
							icon: "success",
							title: 'Welcome!',
							timer: 1000,
							timerProgressBar: true,
							showConfirmButton: false,
							toast: true,
							position: 'top-end',
						}).then(() => {
							this.$router.push('/panel/dashboard');
						})
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.login = false;
				})
		}
	},
	created() {
		/* check if user is logged in */
		if (localStorage.getItem("technician") != null) {
			this.$router.push('/panel/dashboard');
		}
	}
}
</script>

<style lang="scss">
.login-view {
	.login-content {
		max-width: 500px;
		background: white;
		padding: 22px;
		margin: 0 auto;
		margin-top: 24px;
		border: 1px solid #eee;
		border-radius: 12px;
		box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
			0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

		.login-form {
			.login-btn {
				display: block;
				margin: 0 auto;
			}
		}
	}
}
</style>
