<template>
	<div class="panel-wrapper">
		<div class="panel-content">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	methods: {

	},
	created() {

	}
}
</script>

<style lang="scss">

</style>