<template>
	<v-app>
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>

export default {
	name: 'App',

	data: () => ({
		//
	}),
};
</script>

<style lang="scss">
/* fonts */
@import "./assets/fonts/loader.css";

.v-application {
	/* font */
	font-family: "Vazirmatn", sans-serif !important;
	[class*="text-"] {
		font-family: "Vazirmatn", sans-serif !important;
	}

	/* background */
	background-image: url("assets/background-pattern.png") !important;
	background-repeat: repeat !important;
	background-size: 180px !important;
	background-position: center !important;
}
</style>
