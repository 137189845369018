import Vue from 'vue'
import VueRouter from 'vue-router'

/* layouts */
import LayoutPanel from '../views/Layouts/LayoutPanel.vue'

/* views */
import LoginView from '../views/LoginView'
import Dashboard from '../views/panel/DashboardView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/panel',
    name: 'panel',
    component: LayoutPanel,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
