<template>
	<div>
		<v-form class="add-statistic-form mt-5" @submit.prevent="addStatistic">
			<div class="mb-4" style="font-size: 12px; color: rgba(33,45,113,.55);">{{persianDateText}}</div>
			<v-menu v-model="datePickerOpen" offset-y>
				<template v-slot:activator="{ on }">
					<v-text-field outlined label="تاریخ" v-model="persianDate" v-bind:value="statistic.date" v-on:input="statistic.date" @click="datePickerOpen = true" :append-icon="datePickerOpen ? 'mdi-calendar-remove' : 'mdi-calendar'" :append-icon-cb="() => datePickerOpen = !datePickerOpen" v-on="on" readonly></v-text-field>
				</template>
				<v-date-picker v-model="statistic.date" @input="datePickerOpen = false" locale="fa"></v-date-picker>
			</v-menu>

			<v-text-field name="patient-name" label="نام بیمار" type="text" outlined v-model="statistic.patientName"></v-text-field>
			<v-text-field name="grafts-amount" label="تعداد گرفت" type="tel" dir="rtl" outlined v-model="statistic.graftsAmount"></v-text-field>
			<v-checkbox v-model="statistic.introducedCase" label="کیس معرفی" label-before></v-checkbox>
			<v-text-field label="توضیحات (اختیاری)" type="text" outlined v-model="statistic.desc"></v-text-field>

			<v-btn depressed type="submit" color="primary" class="add-statistic-btn" :loading="loading.addStatistic">
				ثبت آمار
			</v-btn>
		</v-form>
	</div>
</template>

<script>
import persianDate from "persian-date";

export default {
	data() {
		return {
			selectedSection: 'view',
			datePickerOpen: false,
			loading: {
				addStatistic: false
			},
			technician: {
				telegram_id: null,
				full_name: null,
				token: null,
			},
			statistic: {
				date: new Date().toISOString().slice(0, 10),
				patientName: '',
				graftsAmount: '',
				introducedCase: false,
				desc: ''
			},
		}
	},
	computed: {
		persianDate() {
			if (this.statistic.date) {
				let date = new persianDate(new Date(this.statistic.date));
				return date.format("YYYY/MM/DD");
			}
			return ''
		},
		persianDateText() {
			if (this.statistic.date) {
				let date = new persianDate(new Date(this.statistic.date));
				return date.format("dddd DD MMMM YYYY");
			}
			return ''
		}
	},
	methods: {
		resetForm() {
			this.statistic.date = new Date().toISOString().slice(0, 10);
			this.statistic.patientName = '';
			this.statistic.graftsAmount = '';
			this.statistic.introducedCase = false;
			this.statistic.desc = '';
		},
		addStatistic() {
			/* call the api */
			this.loading.addStatistic = true;

			/* make data */
			var data = new FormData();
			/* get token from localstorage technician */
			data.append("token", this.$parent.technician.token);


			/* create persian date */
			let date = new persianDate(new Date(this.statistic.date));
			data.append("date", date.toLocale('en').format("YYYY-MM-DD"));

			data.append("patient_name", this.statistic.patientName);
			data.append("grafts_amount", this.statistic.graftsAmount);
			data.append("case_type", this.statistic.introducedCase ? 'ref' : 'clinic');
			data.append("desc", this.statistic.desc);

			/* make call to server */
			this.$http.post("addStatistic", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$swal({
							icon: "success",
							text: "آمار با موفقیت ثبت شد",
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});

						this.resetForm();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.addStatistic = false;
				})
		},
	}
}
</script>

<style lang="scss">
.add-statistic-form {
	.add-statistic-btn {
		display: block;
		margin: 0 auto;
	}
}
</style>