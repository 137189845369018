<template>
	<div class="add-statistic-view">
		<v-container>
			<div class="add-statistic-content">
				<div class="technician-info">
					<h3>{{technician.full_name}}</h3>
					<!-- logout btn -->
					<v-btn depressed outlined color="red darken-3" small class="logout-btn" @click="logout">
						خروج
					</v-btn>
				</div>

				<v-tabs class="tabs-select-section" centered background-color="cyan" dark grow v-model="selectedSection">
					<v-tabs-slider color="blue darken-3"></v-tabs-slider>
					<v-tab>ثبت آمار</v-tab>
					<v-tab>مشاهده گزارش</v-tab>
				</v-tabs>

				<component :is="selectedComponent"></component>
			</div>
		</v-container>
	</div>
</template>

<script>
import AddStatistics from './AddStatistics';
import ViewStatistics from './ViewStatistics';

export default {
	name: 'DashboardView',
	components: {
		AddStatistics,
		ViewStatistics
	},
	data() {
		return {
			selectedSection: 'view',
			technician: {
				telegram_id: null,
				full_name: null,
				token: null,
			},
		}
	},
	computed:{
		selectedComponent(){
			if(this.selectedSection == 0) {
				return 'AddStatistics';
			} else if(this.selectedSection == 1) {
				return 'ViewStatistics';
			}

			return 'AddStatistics';
		}
	},
	methods: {
		logout() {
			localStorage.removeItem("technician");
			this.$router.push('/login');
		}
	},
	created() {
		/* check if user is logged in */
		if (localStorage.getItem("technician") == null) {
			this.$router.push('/login');
		}

		/* get technician info */
		let technician = JSON.parse(localStorage.getItem("technician"));
		this.technician.telegram_id = technician.telegram_id;
		this.technician.full_name = technician.full_name;
		this.technician.token = technician.token;
	}
}
</script>

<style lang="scss">
.add-statistic-view {
	.add-statistic-content {
		max-width: 500px;
		background: white;
		padding: 22px;
		margin: 0 auto;
		margin-top: 24px;
		border: 1px solid #eee;
		border-radius: 12px;
		box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
			0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

		.tabs-select-section {
			border-radius: 10px;
			overflow: hidden;
			
			.v-tabs-slider-wrapper {
				height: 5px !important;
			}
		}

		.technician-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 12px;
			border: 1px solid #eee;
			padding: 15px;
			border-radius: 10px;
			background: #eee;
			margin-bottom: 22px;
		}
	}
}
</style>
