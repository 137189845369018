<template>
	<div class="mt-5">
		<v-row>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(1)" :loading="loading.btn == 0">
					فروردین
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(2)" :loading="loading.btn == 1">
					اردیبهشت
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(3)" :loading="loading.btn == 2">
					خرداد
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(4)" :loading="loading.btn == 3">
					تیر
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(5)" :loading="loading.btn == 4">
					مرداد
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(6)" :loading="loading.btn == 5">
					شهریور
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(7)" :loading="loading.btn == 6">
					مهر
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(8)" :loading="loading.btn == 7">
					آبان
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(9)" :loading="loading.btn == 8">
					آذر
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(10)" :loading="loading.btn == 9">
					دی
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(11)" :loading="loading.btn == 10">
					بهمن
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn outlined color="primary" block @click="viewReport(12)" :loading="loading.btn == 11">
					اسفند
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				btn: -1,
			},
		};
	},
	methods: {
		viewReport(month) {
			this.loading.btn = month - 1;
			
			/* make data */
			var data = new FormData();
			/* get token from localstorage technician */
			data.append("token", this.$parent.technician.token);

			data.append("month", month);

			/* make call to server */
			this.$http.post("viewStatistics", data)
				.then((result) => {
					if (result.data.status == "ok") {
						/* open link in new tab */
						window.location.href = result.data.data.link;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.btn = -1;
				})
		},
	},
}
</script>

<style>
</style>